import './App.css';
import ChatBotLandingPage from './pages/ChatBotLandingPage/ChatBotLandingPage';
import * as React from 'react';
// import ChatBot from './pages/Chatbot';
function App(props: any) {
  return (
    <div className="App">
      <header className="App-header">
        {/* <ChatBot /> */}
      </header>
    </div>
  );
}

export default App;
